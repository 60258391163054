<template>
    <div :class="[$style.signup, classLayout]">
        <div v-if="name === 'dashboard'">
            <Logo
                name="authentication"
            />
        </div>
        <h1 v-if="name === 'standard'" class="pagetitle">
            アカウント登録
        </h1>
        <div>
            <input v-model="input.mailAddress" type="email" placeholder="メールアドレス">
        </div>
        <div>
            <input v-model="input.password" type="password" placeholder="パスワード">
        </div>
        <div v-if="!loader">
            <Button
                text="アカウントを登録する"
                type="signup"
                @action="signUp()"
            />
        </div>
        <Loader
            :loader="loader"
        />
    </div>
</template>

<script>
    "use strict";

    import Button from "@/components/Button.vue";
    import Loader from "@/components/Loader.vue";
    import Logo from "@/components/Logo.vue";

    import firebase from "@/mixins/firebase.js";
    import string from "@/mixins/string.js";

    export default {
        "components": {
            Button,
            Loader,
            Logo,
        },
        "mixins": [
            firebase,
            string,
        ],
        "props": {
            "name": {
                "type": String,
                "required": true,
            },
        },
        data() {
            return {
                "input": {
                    "mailAddress": "",
                    "password": "",
                },
                "loader": false,
            };
        },
        "computed": {
            // layouts別のclassを追加
            classLayout() {
                const adjName = this.$_initialUpperCase(this.name);
                return this.$style[`is${adjName}`];
            },
        },
        "methods": {
            signUp() {
                this.loader = true;
                this.$_signUp(
                    this.input.mailAddress,
                    this.input.password
                ).then(userCredential => {
                    if (this.name === "dashboard") {
                        const uid = userCredential.user.uid;
                        this.$_firestoreCreate({
                            "collection": "administrators",
                            "doc": [uid],
                            "set": {
                                "role": "管理者",
                            },
                        }).then(() => {
                            alert("アカウントを登録しました！");
                            this.loader = false;
                        }).catch(error => {
                            alert(error.message);
                            this.loader = false;
                        });
                    }
                    else {
                        this.$router.push({
                            "path": "/",
                        });
                    }
                }).catch(error => {
                    alert(error.message);
                    this.loader = false;
                });
            },
        },
    };
</script>

<style lang="scss" module>
    .signup {
        > div {
            &:not(:first-child) {
                margin-top: 32px;
            }
            > input {
                border-radius: 8px;
                font-size: 18px;
                padding: 9px;
            }
        }
        &.isDashboard {
            background: #222;
            border-radius: 24px;
            margin: 0 0 32px;
            padding: 64px;
            width: 480px;
            @include sp {
                padding: 64px 32px;
            }
        }
        &.isStandard {
            background: #fff;
            margin: 0 16px;
            padding: 16px 16px 32px;
            @include sp {
                margin-top: 16px;
            }
            > div {
                text-align: center;
            }
        }
    }
</style>
